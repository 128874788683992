.question-text {
    font-weight: bold;
}

.answer-text {
    font-size: 20px;
    padding: 4px 10px 4px 10px;
}

.info-container {
    border: dotted 1px;
    border-color: #3366cc;
    padding: 10px;
    float: left;
    width: 100%;
}

.info-item-container {
    margin-top: 4px;
}

.info-item-label {
    font-weight: bold;
}

.info-item-date-container {
    float: right;
}

.qa-container {
    float: left; 
    margin-top: 10px;
    width: 100%;
}

.qa-line {
    border: dotted 1px;
    border-color: #3366cc;
}

.product-serial-line {
    border: solid 2px #45d464;
    border-color: var(--color-secondary);
}

CardText {
    color: red;
    font-size: 150px;
}

.dialogCloseButton {
    position: fixed;
    margin-left: 93%;
    margin-top: 2%;
}

.dialog{
    padding:30px;
}
.inspection-container
{
    padding:50px;
}

@media print {
    .inspection-container,  .dialog {
        padding: 0px;
        margin:0px;
    }

    .answer-text{
        font-size:12px;
    }
    .qa-container{
        margin:0;
        padding:0;
    }

    .dialogCloseButton {
     visibility:hidden;
    }
}